import { SHARES_SUBGRAPH } from "@/constants/subgraphs";
import { getClient } from "@/graphql/client";
import type { DepositStatus } from "@/graphql/request";
import { useQuery } from "@tanstack/react-query";
import Big from "big.js";

export const useTotalUserDeposits = (
  status: DepositStatus[],
  beneficiary: string,
) => {
  const { data, isLoading, isFetchedAfterMount } = useQuery({
    queryKey: ["deposits", beneficiary, status],
    queryFn: async () => {
      const response = await getClient(SHARES_SUBGRAPH.url).Deposits({
        status,
        beneficiary,
      });
      return response.data.deposits;
    },
    select: (data) => {
      const totalValue = data
        .reduce((sum, deposit) => {
          return sum
            .plus(Big(deposit.value))
            .plus(Big(deposit.priceImpactMargin));
        }, Big(0))
        .toFixed(0);

      const totalShares = data
        .reduce((sum, deposit) => {
          return sum.plus(Big(deposit.shares));
        }, Big(0))
        .toFixed(0);

      return {
        totalValue: totalValue.toString(),
        totalShares: totalShares.toString(),
      };
    },
  });

  return { data, isLoading, isFetchedAfterMount };
};
