import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import type { ChartData, ChartOptions } from "chart.js";
import { formatUSDCtoUSD } from "@/utils/formatters";
import { formatUTC } from "@/utils/date_time";
import { Tabs } from "@/components";

interface NavData {
  id: string;
  timestamp: bigint;
  value: bigint;
}

enum TabType {
  All = "All",
  Year = "1Y",
  Month = "1M",
  Week = "1W",
  Day = "1D",
}

const tabs = [
  TabType.All,
  TabType.Year,
  TabType.Month,
  TabType.Week,
  TabType.Day,
];

const NavData: NavData[] = [
  // { id: "1", timestamp: 1695907200n, value: 24611879162161n },
  // { id: "2", timestamp: 1695908100n, value: 24621879162161n },
  // { id: "3", timestamp: 1695909000n, value: 24631879162161n },
  // { id: "4", timestamp: 1695909900n, value: 24641879162161n },
  // { id: "5", timestamp: 1695910800n, value: 24651879162161n },
  // { id: "6", timestamp: 1695911700n, value: 24661879162161n },
  // { id: "7", timestamp: 1695912600n, value: 24671879162161n },
  // { id: "8", timestamp: 1695913500n, value: 24681879162161n },
  // { id: "9", timestamp: 1695914400n, value: 24691879162161n },
  // { id: "10", timestamp: 1695915300n, value: 24701879162161n },
  // { id: "11", timestamp: 1695916200n, value: 24711879162161n },
  // { id: "12", timestamp: 1695917100n, value: 24721879162161n },
  // { id: "13", timestamp: 1695918000n, value: 24731879162161n },
  // { id: "14", timestamp: 1695918900n, value: 24941879162161n },
  // { id: "15", timestamp: 1695919800n, value: 24751879162161n },
  // { id: "16", timestamp: 1695920700n, value: 24761879162161n },
  // { id: "17", timestamp: 1695921600n, value: 24871879162161n },
  // { id: "12", timestamp: 1695917100n, value: 24721879162161n },
  // { id: "13", timestamp: 1695918000n, value: 24931879162161n },
  // { id: "14", timestamp: 1695918900n, value: 21941579162161n },
  // { id: "15", timestamp: 1695919800n, value: 25751879162161n },
  // { id: "16", timestamp: 1695920700n, value: 24761879162161n },
  // { id: "17", timestamp: 1695921600n, value: 26871879162161n },
  // { id: "12", timestamp: 1695917100n, value: 26721879162161n },
  // { id: "13", timestamp: 1695918000n, value: 24731879162161n },
  // { id: "14", timestamp: 1695918900n, value: 24941879162161n },
  // { id: "15", timestamp: 1695919800n, value: 24751879162161n },
  // { id: "16", timestamp: 1695920700n, value: 24761879162161n },
  // { id: "17", timestamp: 1695921600n, value: 24871879162161n },
  // { id: "18", timestamp: 1695922500n, value: 24781879162161n },
  // { id: "19", timestamp: 1695923400n, value: 24791879162161n },
  // { id: "16", timestamp: 1695920700n, value: 24761879162161n },
  // { id: "17", timestamp: 1695921600n, value: 24871879162161n },
  // { id: "18", timestamp: 1695922500n, value: 24781879162161n },
  // { id: "19", timestamp: 1695923400n, value: 24791879162161n },
  // { id: "16", timestamp: 1695920700n, value: 24761879162161n },
  // { id: "17", timestamp: 1695921600n, value: 24871879162161n },
  // { id: "18", timestamp: 1695922500n, value: 24781879162161n },
  // { id: "19", timestamp: 1695923400n, value: 24791879162161n },
  // { id: "20", timestamp: 1695924300n, value: 24801879162161n },
];

const calculateYAxisBounds = (data: NavData[]) => {
  const minValue = Math.min(...data.map((d) => Number(d.value)));
  const maxValue = Math.max(...data.map((d) => Number(d.value)));
  const step = (maxValue - minValue) / 4;
  return {
    min: minValue - step / 2,
    max: maxValue + step / 2,
  };
};

export default function NavVariationChart() {
  const [currentTab, setCurrentTab] = useState<TabType>(TabType.All);

  const hasData = NavData && NavData.length > 0;

  const { min, max } = hasData
    ? calculateYAxisBounds(NavData)
    : { min: 0, max: 100 };

  const options: ChartOptions<"line"> = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        min,
        max,
        grid: {
          color: "#808080",
          lineWidth: 1,
        },
        border: {
          dash: [6, 4],
          color: "#808080",
          width: 2,
        },
        ticks: {
          color: "#808080",
          stepSize: (max - min) / 4,
          callback: (value) => {
            return formatUSDCtoUSD(BigInt(value));
          },
        },
      },
      x: {
        grid: {
          color: "#808080",
          lineWidth: 1,
        },
        border: {
          dash: [6, 4],
          color: "#808080",
          width: 2,
        },
        ticks: {
          color: "#808080",
          maxTicksLimit: 6,
          callback: (value, index) => {
            if (hasData) {
              return formatUTC(NavData[index].timestamp);
            }
            return "";
          },
        },
      },
    },
  };

  const formattedData: ChartData<"line"> = {
    labels: hasData ? NavData.map((data) => data.timestamp.toString()) : [],
    datasets: [
      {
        label: "NAV value",
        data: hasData ? NavData.map((data) => Number(data.value)) : [],
        borderColor: "#0F8151",
        tension: 0.5,
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="pt-5 space-y-10">
      <div className="flex justify-between items-center">
        <span className="font-mono text-gray-900 font-bold text-2xl">NAV</span>
        <Tabs
          tabs={tabs}
          selectedIndex={tabs.indexOf(currentTab)}
          setCurrentTab={(tab) => {
            setCurrentTab(tabs[tab]);
          }}
        />
      </div>
      <div className="h-96 w-full">
        <Line data={formattedData} options={options} />
      </div>
    </div>
  );
}
