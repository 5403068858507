import { fetchLatestState } from "@/data/api/requests";
import { useQuery } from "@tanstack/react-query";

interface UseLatestStateOptions {
  enableRefetch?: boolean;
}

export const useLatestState = ({
  enableRefetch = false,
}: UseLatestStateOptions = {}) => {
  const { data, isError, isFetching, isFetchedAfterMount } = useQuery({
    queryKey: ["latestState"],
    queryFn: fetchLatestState,
    refetchInterval: enableRefetch ? 10000 : false,
    refetchOnReconnect: enableRefetch,
    refetchOnWindowFocus: enableRefetch,
  });

  const state = data
    ? {
        nav: BigInt(data.nav.usd),
        supply: BigInt(data.supply.totalSupply),
      }
    : undefined;

  return {
    data: state,
    isError,
    isFetching,
    isFetchedAfterMount,
  };
};
