import { useQuery } from "@tanstack/react-query";
import { differenceInDays } from "date-fns";

import { getAnnualizedReturn } from "@/utils/wallet";
import { getClient } from "@/graphql/client";
import { SHARES_SUBGRAPH } from "@/constants/subgraphs";

async function fetchVaultFirstIssuedTimestamp() {
  const response = await getClient(SHARES_SUBGRAPH.url).VaultSharesFirstIssue();
  return response.data.vaultShares_collection[0]?.timestamp;
}

export function useAnnualizedReturn(
  initialSharePrice: number,
  currSharePrice?: number,
) {
  const { data, isFetchedAfterMount } = useQuery({
    queryKey: ["vaultFirstIssuedTimestamp"],
    queryFn: async () => {
      const firstIssuedTimestamp = await fetchVaultFirstIssuedTimestamp();

      if (!firstIssuedTimestamp) {
        return undefined;
      }

      const issueDate = new Date(firstIssuedTimestamp * 1000);
      const currentDate = new Date();

      const daysHeld = differenceInDays(currentDate, issueDate) + 1;

      return { daysHeld };
    },
    enabled: !!initialSharePrice && !!currSharePrice,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const daysHeld = data?.daysHeld ?? 0;

  const annualizedReturn =
    initialSharePrice && currSharePrice !== undefined && daysHeld > 0
      ? getAnnualizedReturn(initialSharePrice, currSharePrice, daysHeld)
      : undefined;

  return {
    annualizedReturn,
    isFetchedAfterMount,
  };
}
