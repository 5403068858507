import { useQueries } from "@tanstack/react-query";
import { readContract } from "@wagmi/core";
import { erc20Abi } from "@/wagmi.generated";
import { config } from "@/wagmi";
import { CONTRACT_ADDRESSES, SAFES_ADDRESSES } from "@/constants/addresses";
import Big from "big.js";
import { DECIMALS } from "@/constants/wallet";
import { percentageFormatter } from "@/utils/formatters";

export const useCapitalUtilization = (
  nav: bigint | undefined,
  enabled: boolean,
) => {
  const queries = SAFES_ADDRESSES.map((safeAddress) => ({
    enabled: enabled,
    queryKey: ["usdcBalance", safeAddress, nav?.toString()],
    queryFn: async (): Promise<string> => {
      const balance: bigint = await readContract(config, {
        address: CONTRACT_ADDRESSES.USDC,
        abi: erc20Abi,
        functionName: "balanceOf",
        args: [safeAddress],
      });
      return balance.toString();
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  }));

  const results = useQueries({
    queries,
    combine: (balanceResults) => {
      const hasUndefinedData = balanceResults.some(
        (result) => result.data === undefined,
      );

      const totalUsdcInSafes = hasUndefinedData
        ? undefined
        : balanceResults.reduce((acc: bigint, result) => {
            return acc + BigInt(result.data ?? "0");
          }, 0n);

      const capitalUtilizationPercentage =
        totalUsdcInSafes !== undefined && !!nav
          ? calculateCapitalUtilization(totalUsdcInSafes, nav)
          : undefined;

      return {
        capitalUtilizationPercentage,
        isLoading: balanceResults.some((result) => result.isLoading),
        isError: balanceResults.some((result) => result.isError),
        error: balanceResults.find((result) => result.isError)?.error,
        isFetchedAfterMount: balanceResults.every(
          (result) => result.isFetchedAfterMount,
        ),
        isFetched: balanceResults.every((result) => result.isFetched),
      };
    },
  });

  return results;
};

const calculateCapitalUtilization = (
  totalBalance: bigint,
  nav: bigint,
): string => {
  const totalBalanceBig = Big(totalBalance.toString());
  const navBig = Big(nav.toString());

  const scalling = Big(10).pow(DECIMALS.NAV - DECIMALS.USDC);

  const scaledUSDC = totalBalanceBig.times(scalling);

  let unusedUSDCPercentage = scaledUSDC.div(navBig).toNumber();

  unusedUSDCPercentage = Math.max(0, Math.min(1, unusedUSDCPercentage));

  return percentageFormatter.format(1 - unusedUSDCPercentage);
};
