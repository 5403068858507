import { DECIMALS, SYMBOLS } from "@/constants/wallet";
import { formatUnits, parseUnits } from "viem";

export const percentageFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const sharesFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
});

export const usdcFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function formatUSDCtoUSD(value: bigint): string {
  const formattedValue = formatUnits(value, DECIMALS.USDC);
  const numberValue = Number.parseFloat(formattedValue);
  return usdFormatter.format(numberValue);
}

export function parseToUSDCUnits(amount: string): bigint {
  return parseUnits(amount, DECIMALS.USDC);
}

export function formatToUSDCUnits(
  amount: bigint,
  includeTicker = true,
): string {
  const formattedAmount = Number.parseFloat(formatUnits(amount, DECIMALS.USDC));
  if (!includeTicker) return formattedAmount.toString();
  const formattedString = usdcFormatter.format(formattedAmount);
  return `${formattedString} ${SYMBOLS.USDC}`;
}

export function parseToSharesUnits(amount: string): bigint {
  return parseUnits(amount, DECIMALS.SHARES);
}

export function formatToSharesUnits(
  amount: bigint,
  includeTicker = true,
): string {
  const formattedAmount = Number.parseFloat(
    formatUnits(amount, DECIMALS.SHARES),
  );
  if (!includeTicker) return formattedAmount.toString();

  const formattedString = sharesFormatter.format(formattedAmount);
  return `${formattedString} ${SYMBOLS.SHARES}`;
}

export const formatToPercentage = (value: number): string => {
  return percentageFormatter.format(value);
};

export function formatToUSD(value: number): string {
  return usdFormatter.format(value);
}
