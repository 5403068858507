"use client";

import useAuthentication from "@/hooks/useAuthentication";
import LabelValue from "../LabelValue";
import { useTotalUserRedeems } from "@/hooks/useTotalUserRedeem";
import { RedeemStatus } from "@/graphql/request";
import { formatToSharesUnits, formatToUSDCUnits } from "@/utils/formatters";

export default function RedemptionData() {
  const { address } = useAuthentication();
  const { data, isFetchedAfterMount } = useTotalUserRedeems(
    [RedeemStatus.Approved],
    address,
  );

  const totalValue = data?.totalValue;
  const totalShares = data?.totalShares;

  return (
    <div className="grid grid-cols-3 gap-4 w-full pt-10 px-5">
      <div className="col-span-1 px-2">
        <LabelValue
          isFetched={isFetchedAfterMount}
          label="Reedeemed amount"
          value={
            totalValue !== undefined
              ? formatToUSDCUnits(BigInt(totalValue))
              : undefined
          }
        />
      </div>
      <div className="col-span-1 px-2">
        <LabelValue
          isFetched={isFetchedAfterMount}
          label="# shares"
          showSmallerValue={true}
          value={
            totalShares !== undefined
              ? formatToSharesUnits(BigInt(totalShares))
              : undefined
          }
        />
      </div>
    </div>
  );
}
