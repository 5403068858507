"use client";

import { useTotalUserDeposits } from "@/hooks/useTotalUserDeposit";
import LabelValue from "../LabelValue";
import useAuthentication from "@/hooks/useAuthentication";
import { DepositStatus } from "@/graphql/request";
import { formatToSharesUnits, formatToUSDCUnits } from "@/utils/formatters";

export default function SubscriptionData() {
  const { address } = useAuthentication();
  const { data, isFetchedAfterMount } = useTotalUserDeposits(
    [DepositStatus.Approved],
    address,
  );

  const totalValue = data?.totalValue;
  const totalShares = data?.totalShares;

  return (
    <div className="grid grid-cols-3 gap-4 w-full mt-10 pt-5 pb-10 border-b border-gray-400 px-5">
      <div className="col-span-1 px-2">
        <LabelValue
          isFetched={isFetchedAfterMount}
          label="Subscribed amount"
          value={
            totalValue !== undefined
              ? formatToUSDCUnits(BigInt(totalValue))
              : undefined
          }
        />
      </div>
      <div className="col-span-1 px-2 space-y-10">
        <LabelValue
          isFetched={isFetchedAfterMount}
          label="# shares"
          showSmallerValue={true}
          value={
            totalShares !== undefined
              ? formatToSharesUnits(BigInt(totalShares))
              : undefined
          }
        />
      </div>
      <div className="col-span-1 px-2 space-y-10">
        <LabelValue
          label="Realised Returns"
          showSmallerValue={true}
          value={"-"}
        />
        <LabelValue
          label="Unrealised returns"
          showSmallerValue={true}
          value={"-"}
        />
      </div>
    </div>
  );
}
