import type { SharesQueryInfo } from "@/data/interfaces/manage_assets";
import SectionTitle from "../SectionTitle";
import FundPerformanceData from "./FundPerformanceData";
import FundPerformanceCharts from "./FundPerformanceCharts";

interface FundPerformanceProps {
  isStateFetched: boolean;
  sharePrice: number | undefined;
}

export default function FundPerformance({
  isStateFetched,
  sharePrice,
}: FundPerformanceProps) {
  return (
    <div className="py-10 border-b border-b-gray-800">
      <div className="px-5 space-y-10">
        <SectionTitle>Fund performance</SectionTitle>
        <FundPerformanceData
          isStateFetched={isStateFetched}
          sharePrice={sharePrice}
        />
        <FundPerformanceCharts />
      </div>
    </div>
  );
}
