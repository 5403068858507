import { SHARES_SUBGRAPH } from "@/constants/subgraphs";
import { getClient } from "@/graphql/client";
import type { RedeemStatus } from "@/graphql/request";
import { useQuery } from "@tanstack/react-query";
import Big from "big.js";

export const useTotalUserRedeems = (
  status: RedeemStatus[],
  beneficiary: string,
) => {
  const { data, isLoading, isFetchedAfterMount } = useQuery({
    queryKey: ["redeems", beneficiary, status],
    queryFn: async () => {
      const response = await getClient(SHARES_SUBGRAPH.url).Redeems({
        status,
        beneficiary,
      });
      return response.data.redeems;
    },
    select: (data) => {
      const totalValue = data
        .reduce((sum, redeem) => {
          return sum.plus(Big(redeem.value));
        }, Big(0))
        .toFixed(0);

      const totalShares = data
        .reduce((sum, redeem) => {
          return sum.plus(Big(redeem.shares));
        }, Big(0))
        .toFixed(0);

      return {
        totalValue: totalValue.toString(),
        totalShares: totalShares.toString(),
      };
    },
  });

  return { data, isLoading, isFetchedAfterMount };
};
