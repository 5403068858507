"use client";

import ArrowUpRight from "@/components/icons/ArrowUpRight";
import { SAFES_ADDRESSES } from "@/constants/addresses";
import { openAddressBlockExplorer, shortenAddress } from "@/utils/addresses";

import { Button } from "@headlessui/react";
import type { Address } from "viem";

export default function SafeAddresses() {
  return (
    <div className="py-10 flex flex-row">
      {SAFES_ADDRESSES.map((safeAddress) => (
        <SafeAddress key={safeAddress} safeAddress={safeAddress} />
      ))}
    </div>
  );
}

function SafeAddress({ safeAddress }: { safeAddress: Address }) {
  return (
    <Button
      onClick={() => openAddressBlockExplorer(safeAddress)}
      className="bg-gray-900 rounded-[80px] py-2 gap-3 flex flex-row items-center mx-4 pl-6 pr-4"
    >
      <span className="font-mono font-bold text-base text-gray-100">
        {shortenAddress(safeAddress)}
      </span>
      <ArrowUpRight size={20} color="#fff" />
    </Button>
  );
}
