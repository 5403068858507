import { INITIAL_SHARE_PRICE } from "@/constants/wallet";
import LabelValue from "../LabelValue";
import { useAnnualizedReturn } from "@/hooks/useAnnualizedReturn";
import { getTotalReturnPercentage } from "@/utils/wallet";

interface FundPerformanceDataProps {
  isStateFetched: boolean;
  sharePrice: number | undefined;
}

export default function FundPerformanceData({
  isStateFetched,
  sharePrice,
}: FundPerformanceDataProps) {
  const {
    annualizedReturn,
    isFetchedAfterMount: isAnnuallizedReturnFetchedOnMount,
  } = useAnnualizedReturn(1, sharePrice);
  return (
    <div className="grid grid-cols-3 md:grid-cols-5 space-x-4 w-full">
      <LabelValue
        label="Total return"
        isFetched={isStateFetched}
        value={
          sharePrice !== undefined
            ? getTotalReturnPercentage(INITIAL_SHARE_PRICE, sharePrice)
            : undefined
        }
      />
      <LabelValue
        label="Annualised return"
        isFetched={isAnnuallizedReturnFetchedOnMount || isStateFetched}
        value={annualizedReturn}
      />
      <LabelValue label="Alpha" value="-" />
      <LabelValue label="Beta" value="-" />
      <LabelValue label="Sharpe ratio" value="-" />
    </div>
  );
}
