import Big from "big.js";
import { formatToPercentage, formatToUSD } from "./formatters";
import { DECIMALS, INITIAL_SHARE_PRICE } from "@/constants/wallet";

export function getTransactionSharePrice(
  sharesAmount: string,
  usdcAmount: string,
  priceImpactMargin: string,
): string {
  const sharesBig = Big(sharesAmount);
  const usdcBig = Big(usdcAmount);
  const marginBig = Big(priceImpactMargin);

  const combinedUSDC = usdcBig.plus(marginBig);

  const scalling = Big(10).pow(DECIMALS.SHARES - DECIMALS.USDC);

  const scaledUSDCAmount = combinedUSDC.times(scalling);

  const sharePrice = scaledUSDCAmount.div(sharesBig);

  return formatToUSD(sharePrice.toNumber());
}

export function getScalledShareValueBasedOnNav(
  shares: bigint,
  nav: bigint,
): number {
  if (shares === 0n) {
    return INITIAL_SHARE_PRICE;
  }
  const sharesBig = Big(shares.toString());
  const navBig = Big(nav.toString());

  const scalling = Big(10).pow(DECIMALS.SHARES - DECIMALS.NAV);

  const scaledNavBig = navBig.times(scalling);

  const sharePrice = scaledNavBig.div(sharesBig);

  return sharePrice.toNumber();
}

export function getTotalReturnPercentage(
  initialSharePrice: number,
  currSharePrice: number,
): string {
  const sharePriceDifference = currSharePrice - initialSharePrice;
  const totalReturnPercentage = sharePriceDifference / initialSharePrice;

  return formatToPercentage(totalReturnPercentage);
}

export function getAnnualizedReturn(
  initialSharePrice: number,
  currSharePrice: number,
  daysHeld: number,
): string {
  const cumulativeReturn =
    (currSharePrice - initialSharePrice) / initialSharePrice;

  if (daysHeld < 30) {
    return formatToPercentage(cumulativeReturn);
  }

  const annualizedReturn = (1 + cumulativeReturn) ** (365 / daysHeld) - 1;

  return formatToPercentage(annualizedReturn);
}
