"use client";

import LabelValue from "../LabelValue";
import { CONTRACT_ADDRESSES } from "@/constants/addresses";
import { useCapitalUtilization } from "@/hooks/useCapitalUtilization";
import { SYMBOLS } from "@/constants/wallet";
import { formatToSharesUnits, formatToUSD } from "@/utils/formatters";
import ArrowUpRight from "@/components/icons/ArrowUpRight";
import type { StateQueryInfo } from "@/data/interfaces/fund_state";
import { openAddressBlockExplorer } from "@/utils/addresses";

interface FundPropertiesDataProps {
  stateInfo: StateQueryInfo;
  sharePrice: number | undefined;
  isQueryEnabled: boolean;
}

export default function FundPropertiesData({
  stateInfo,
  isQueryEnabled,
  sharePrice,
}: FundPropertiesDataProps) {
  const {
    data,
    isFetchedAfterMount: isStateFetchedAfterMount,
    isError: isStateError,
  } = stateInfo;

  const {
    capitalUtilizationPercentage,
    isFetchedAfterMount: isCapitalUtilizationFetchedAfterMount,
  } = useCapitalUtilization(data?.nav, isQueryEnabled);

  return (
    <div className="flex gap-20 px-5">
      <LabelValue label="Denomination Asset" value={SYMBOLS.USDC} />
      <LabelValue
        isFetched={isCapitalUtilizationFetchedAfterMount || isStateError}
        label="Capital Utilization"
        value={capitalUtilizationPercentage}
      />
      <LabelValue
        isFetched={isStateFetchedAfterMount}
        label="Share Supply"
        icon={
          <ArrowUpRight
            width={24}
            height={24}
            onClick={() => openAddressBlockExplorer(CONTRACT_ADDRESSES.SHARES)}
            className="cursor-pointer"
          />
        }
        value={
          data?.supply !== undefined
            ? formatToSharesUnits(data.supply)
            : undefined
        }
      />
      <LabelValue
        isFetched={isStateFetchedAfterMount}
        label="Share Price"
        value={sharePrice ? formatToUSD(sharePrice) : undefined}
      />
    </div>
  );
}
