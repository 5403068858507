import axios from "axios";
import type {
  LatestNAVResponse,
  LatestStateResponse,
} from "../interfaces/fund_state";

const API_URL = process.env.NEXT_PUBLIC_API_URL;
const env = process.env.NEXT_PUBLIC_CHAIN_ENV;

const FUND_ID = env === "local" ? "test-fund" : "staging-fund";

export async function fetchLatestNAV(): Promise<LatestNAVResponse> {
  try {
    const response = await axios.get<LatestNAVResponse>(
      `${API_URL}/fund/${FUND_ID}/nav`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching latest NAV:", error);
    throw new Error("Failed to fetch latest NAV");
  }
}

export async function fetchLatestState(): Promise<LatestStateResponse> {
  try {
    const response = await axios.get<LatestStateResponse>(
      `${API_URL}/fund/${FUND_ID}/state`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching latest state:", error);
    throw new Error("Failed to fetch latest state");
  }
}
