import type { StateQueryInfo } from "@/data/interfaces/fund_state";
import FundPropertiesData from "./FundPropertiesData";
import FundPropertiesHeader from "./FundPropertiesHeader";
import SafeAddresses from "./SafeAddresses";

interface FundPropertiesProps {
  stateInfo: StateQueryInfo;
  sharePrice: number | undefined;
  isQueryEnabled: boolean;
}

export default function FundProperties({
  stateInfo,
  sharePrice,
  isQueryEnabled,
}: FundPropertiesProps) {
  return (
    <div className="py-10 border-b border-b-gray-800">
      <FundPropertiesHeader />
      <SafeAddresses />
      <FundPropertiesData
        stateInfo={stateInfo}
        sharePrice={sharePrice}
        isQueryEnabled={isQueryEnabled}
      />
    </div>
  );
}
