"use client";

import type React from "react";

import FundProperties from "./_components/fund_properties/FundProperties";
import UserPosition from "./_components/user_position/UserPosition";
import FundPerformance from "./_components/fund_performance/FundPerformance";
import { getScalledShareValueBasedOnNav } from "@/utils/wallet";
import { useAuth } from "@/context/authContext";
import { useLatestState } from "@/hooks/useLatestState";

export default function ReportPage() {
  const { isLoggedIn } = useAuth();
  const stateInfo = useLatestState();

  const { data, isFetching, isFetchedAfterMount } = stateInfo;

  const isQueryEnabled =
    data !== undefined && isFetchedAfterMount && !isFetching;

  const sharePrice =
    data !== undefined
      ? getScalledShareValueBasedOnNav(data.supply, data.nav)
      : undefined;

  return (
    <>
      <FundProperties
        stateInfo={stateInfo}
        sharePrice={sharePrice}
        isQueryEnabled={isQueryEnabled}
      />
      <FundPerformance
        isStateFetched={isFetchedAfterMount}
        sharePrice={sharePrice}
      />
      {isLoggedIn && <UserPosition />}
    </>
  );
}
