import SectionTitle from "../SectionTitle";
import RedemptionData from "./RedemptionData";
import SubscriptionData from "./SubscriptionData";

export default function UserPosition() {
  return (
    <div className="py-10">
      <div className="px-5">
        <SectionTitle>Your position</SectionTitle>
      </div>
      <SubscriptionData />
      <RedemptionData />
    </div>
  );
}
